@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background: rgb(226 232 240);
    border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(148 163 184);
    border-radius: 5px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(100 116 139);
}

/* Firefox ve diğer bazı tarayıcılar */
input[type="number"] {
    -moz-appearance: textfield;
}

/* Chrome ve Safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}